<template>
  <div>
    <h2>Estado de Pagos</h2>
    <br />
    <v-btn
      dark
      :outlined="year == yearselected"
      color="primary"
      class="mr-4"
      v-for="year in years"
      :key="year"
      @click="
        $router.push({
          name: 'membresia-pagos',
          params: { id: id },
          query: { year: year },
        })
      "
    >
      {{ year }}
    </v-btn>
    <br /><br />
    <ul v-for="(item, index) in payments" :key="index">
      <li>
        <v-row>
          <v-col cols="2">
            <strong> {{ mes(item.createdAt) }}</strong>
          </v-col>
          <v-col cols="2">
            {{ item.nice_status }}
          </v-col>
          <v-col cols="2">
            <v-icon v-if="item.nice_status === 'En curso'">
              mdi-alert-circle-outline
            </v-icon>
            <v-icon v-else-if="item.nice_status === 'Pagado'" color="green">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else-if="item.nice_status === 'Vencido'" color="red">
              mdi-close-circle-outline
            </v-icon>
          </v-col>

          <v-col cols="2">
            <v-btn icon color="green" v-if="item.status != 1" @click="$router.push({name:'membresias-pagos-edit', params:{id:item.id}})">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="2">
            <a
              style="color: #479b25"
              @click="
                $router.push({
                  name: 'membresia-pago-detail',
                  params: { id: id, paymentId: item.id },
                })
              "
              >Ver más
            </a>
          </v-col>
        </v-row>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "payments",
  watch: {
    year(value) {
      this.yearselected = value;
      this.getFetch();
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    year() {
      return this.$route.query.year;
    },
    ...mapState({
      payments: (state) => state.payment.payments,
      fetchingData: (state) => state.fetchingData,
    }),
  },
  data: () => ({
    yearselected: null,
    years: [],
  }),
  methods: {
    ...mapActions(["fetchPayments"]),
    getLast5years() {
      var date = new Date(),
        years = [],
        year = date.getUTCFullYear();
      for (var i = year; i > year - 4; i--) {
        years.push(i);
      }
      this.years = years;
    },
    getRequestParams(year, membership_id) {
      let params = {};
      if (year) {
        params["year"] = year;
      }
      if (membership_id) {
        params["membership_id"] = membership_id;
      }
      return params;
    },
    getFetch() {
      const params = this.getRequestParams(this.year, this.id);
      this.fetchPayments({ params: params });
    },
  },
  created() {
    this.getLast5years();
    this.yearselected = this.year;
    this.getFetch();
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
</style>