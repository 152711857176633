var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Estado de Pagos")]),_c('br'),_vm._l((_vm.years),function(year){return _c('v-btn',{key:year,staticClass:"mr-4",attrs:{"dark":"","outlined":year == _vm.yearselected,"color":"primary"},on:{"click":function($event){return _vm.$router.push({
        name: 'membresia-pagos',
        params: { id: _vm.id },
        query: { year: year },
      })}}},[_vm._v(" "+_vm._s(year)+" ")])}),_c('br'),_c('br'),_vm._l((_vm.payments),function(item,index){return _c('ul',{key:index},[_c('li',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.mes(item.createdAt)))])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.nice_status)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[(item.nice_status === 'En curso')?_c('v-icon',[_vm._v(" mdi-alert-circle-outline ")]):(item.nice_status === 'Pagado')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):(item.nice_status === 'Vencido')?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[(item.status != 1)?_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.$router.push({name:'membresias-pagos-edit', params:{id:item.id}})}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('a',{staticStyle:{"color":"#479b25"},on:{"click":function($event){return _vm.$router.push({
                name: 'membresia-pago-detail',
                params: { id: _vm.id, paymentId: item.id },
              })}}},[_vm._v("Ver más ")])])],1)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }